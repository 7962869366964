export let softs = [
 {
  "id": "1",
  "ini": "す",
  "name": "スーパーサンダーブレード",
  "maker": "セガ",
  "releaseDate": "1988/10/29",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "2",
  "ini": "す",
  "name": "スペースハリアーⅡ",
  "maker": "セガ",
  "releaseDate": "1988/10/29",
  "price": "5800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "3",
  "ini": "し",
  "name": "獣王記",
  "maker": "セガ",
  "releaseDate": "1988/11/27",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "4",
  "ini": "お",
  "name": "おそ松くん はちゃめちゃ劇場",
  "maker": "セガ",
  "releaseDate": "1988/12/24",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "5",
  "ini": "あ",
  "name": "アレックスキッド 天空魔城",
  "maker": "セガ",
  "releaseDate": "1989/2/10",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "6",
  "ini": "ふ",
  "name": "ファンタシースターⅡ 還らざる時の終わりに",
  "maker": "セガ",
  "releaseDate": "1989/3/21",
  "price": "8800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "7",
  "ini": "す",
  "name": "スーパーリーグ",
  "maker": "セガ",
  "releaseDate": "1989/4/22",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "8",
  "ini": "す",
  "name": "スーパー大戦略",
  "maker": "セガ",
  "releaseDate": "1989/4/29",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "9",
  "ini": "さ",
  "name": "サンダーフォースⅡ MD",
  "maker": "テクノソフト",
  "releaseDate": "1989/6/15",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "10",
  "ini": "ほ",
  "name": "北斗の拳 新世紀末救世主伝説",
  "maker": "セガ",
  "releaseDate": "1989/7/1",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "11",
  "ini": "わ",
  "name": "ワールドカップサッカー",
  "maker": "セガ",
  "releaseDate": "1989/7/29",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "12",
  "ini": "た",
  "name": "大魔界村",
  "maker": "セガ",
  "releaseDate": "1989/8/3",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "13",
  "ini": "お",
  "name": "尾崎直道のスーパーマスターズ",
  "maker": "セガ",
  "releaseDate": "1989/9/9",
  "price": "6000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "14",
  "ini": "す",
  "name": "スーパーハイドライド",
  "maker": "アスミック",
  "releaseDate": "1989/10/6",
  "price": "7900",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "15",
  "ini": "す",
  "name": "スーパーハングオン",
  "maker": "セガ",
  "releaseDate": "1989/10/6",
  "price": "6000",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "16",
  "ini": "ら",
  "name": "ランボーⅢ",
  "maker": "セガ",
  "releaseDate": "1989/10/21",
  "price": "5500",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "17",
  "ini": "ふ",
  "name": "フォゴットンワールズ",
  "maker": "セガ",
  "releaseDate": "1989/11/18",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "18",
  "ini": "く",
  "name": "孔雀王2 幻影城",
  "maker": "セガ",
  "releaseDate": "1989/11/25",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "19",
  "ini": "さ",
  "name": "ザ・スーパー忍",
  "maker": "セガ",
  "releaseDate": "1989/12/2",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "20",
  "ini": "た",
  "name": "TATSUJIN",
  "maker": "セガ",
  "releaseDate": "1989/12/9",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "21",
  "ini": "ま",
  "name": "マージャンCOP竜 白狼の野望",
  "maker": "セガ",
  "releaseDate": "1989/12/14",
  "price": "5500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "22",
  "ini": "へ",
  "name": "ヘルツォーク ツヴァイ",
  "maker": "テクノソフト",
  "releaseDate": "1989/12/15",
  "price": "6800",
  "genre": "リアルタイム・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "23",
  "ini": "う",
  "name": "ヴァーミリオン",
  "maker": "セガ",
  "releaseDate": "1989/12/16",
  "price": "8500",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "24",
  "ini": "か",
  "name": "カース",
  "maker": "マイクロネット",
  "releaseDate": "1989/12/23",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "25",
  "ini": "こ",
  "name": "ゴールデンアックス",
  "maker": "セガ",
  "releaseDate": "1989/12/23",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "26",
  "ini": "す",
  "name": "ズーム",
  "maker": "セガ",
  "releaseDate": "1990/1/13",
  "price": "5500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "27",
  "ini": "し",
  "name": "史上最大の倉庫番",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/1/30",
  "price": "5200",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "28",
  "ini": "そ",
  "name": "ソーサリアン",
  "maker": "セガ",
  "releaseDate": "1990/2/24",
  "price": "7000",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "29",
  "ini": "す",
  "name": "スーパーリアルバスケットボール",
  "maker": "セガ",
  "releaseDate": "1990/3/2",
  "price": "6000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "30",
  "ini": "さ",
  "name": "ザ・ニュージーランドストーリー",
  "maker": "タイトー",
  "releaseDate": "1990/3/3",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "31",
  "ini": "え",
  "name": "エアダイバー",
  "maker": "アスミック",
  "releaseDate": "1990/3/9",
  "price": "6800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "32",
  "ini": "し",
  "name": "重装騎兵レイノス",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/3/16",
  "price": "6200",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "33",
  "ini": "あ",
  "name": "アフターバーナーⅡ",
  "maker": "電波新聞社",
  "releaseDate": "1990/3/23",
  "price": "6900",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "34",
  "ini": "ふ",
  "name": "ファイナルブロー",
  "maker": "タイトー",
  "releaseDate": "1990/3/23",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "35",
  "ini": "た",
  "name": "ダーウィン4081",
  "maker": "セガ",
  "releaseDate": "1990/4/7",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "36",
  "ini": "と",
  "name": "時の継承者 ファンタシースターⅢ",
  "maker": "セガ",
  "releaseDate": "1990/4/21",
  "price": "8700",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "37",
  "ini": "さ",
  "name": "サイオブレード",
  "maker": "シグマ商事",
  "releaseDate": "1990/4/27",
  "price": "8500",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "38",
  "ini": "て",
  "name": "DJボーイ",
  "maker": "セガ",
  "releaseDate": "1990/5/19",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "39",
  "ini": "う",
  "name": "ウィップラッシュ 惑星ボルテガスの謎",
  "maker": "セガ",
  "releaseDate": "1990/5/26",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "40",
  "ini": "て",
  "name": "TEL・TEL まあじゃん",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/6/8",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "41",
  "ini": "さ",
  "name": "サンダーフォースⅢ",
  "maker": "テクノソフト",
  "releaseDate": "1990/6/8",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "42",
  "ini": "た",
  "name": "大旋風",
  "maker": "セガ",
  "releaseDate": "1990/6/23",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "43",
  "ini": "こ",
  "name": "ゴーストバスターズ",
  "maker": "セガ",
  "releaseDate": "1990/6/30",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "44",
  "ini": "こ",
  "name": "コラムス",
  "maker": "セガ",
  "releaseDate": "1990/6/30",
  "price": "5500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "45",
  "ini": "さ",
  "name": "サイバーポリス イースワット",
  "maker": "セガ",
  "releaseDate": "1990/7/14",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "46",
  "ini": "ふ",
  "name": "フェリオス",
  "maker": "ナムコ",
  "releaseDate": "1990/7/20",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "47",
  "ini": "は",
  "name": "バットマン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/7/27",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "48",
  "ini": "さ",
  "name": "サイバーボール",
  "maker": "セガ",
  "releaseDate": "1990/7/28",
  "price": "6000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "49",
  "ini": "す",
  "name": "スーパーモナコGP",
  "maker": "セガ",
  "releaseDate": "1990/8/9",
  "price": "6000",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "50",
  "ini": "ら",
  "name": "ラスタンサーガⅡ",
  "maker": "タイトー",
  "releaseDate": "1990/8/10",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "51",
  "ini": "し",
  "name": "四天明王",
  "maker": "シグマ商事",
  "releaseDate": "1990/8/10",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "52",
  "ini": "ま",
  "name": "マイケルジャクソンズ ムーンウォーカー",
  "maker": "セガ",
  "releaseDate": "1990/8/25",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "53",
  "ini": "え",
  "name": "XDR",
  "maker": "ユニパック",
  "releaseDate": "1990/8/26",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "54",
  "ini": "い",
  "name": "インセクターX",
  "maker": "ホット・ビィ",
  "releaseDate": "1990/9/7",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "55",
  "ini": "く",
  "name": "クラックス",
  "maker": "ナムコ",
  "releaseDate": "1990/9/7",
  "price": "4900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "56",
  "ini": "す",
  "name": "スペースインベーダー90",
  "maker": "タイトー",
  "releaseDate": "1990/9/7",
  "price": "5900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "57",
  "ini": "へ",
  "name": "ヘルファイアー",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/9/28",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "58",
  "ini": "す",
  "name": "ストライダー飛竜",
  "maker": "セガ",
  "releaseDate": "1990/9/29",
  "price": "7000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "59",
  "ini": "れ",
  "name": "レインボー・アイランド・エキストラ",
  "maker": "タイトー",
  "releaseDate": "1990/10/5",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "60",
  "ini": "え",
  "name": "FZ戦記AXIS",
  "maker": "ウルフチーム",
  "releaseDate": "1990/10/12",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "61",
  "ini": "ふ",
  "name": "ファットマン",
  "maker": "サンリツ電気",
  "releaseDate": "1990/10/12",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "62",
  "ini": "は",
  "name": "バーニングフォース",
  "maker": "ナムコ",
  "releaseDate": "1990/10/19",
  "price": "5800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "63",
  "ini": "あ",
  "name": "アローフラッシュ",
  "maker": "セガ",
  "releaseDate": "1990/10/20",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "64",
  "ini": "て",
  "name": "TEL・TELスタジアム",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/10/21",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "65",
  "ini": "た",
  "name": "ダイナマイトデューク",
  "maker": "セガ",
  "releaseDate": "1990/10/27",
  "price": "6000",
  "genre": "アクション・シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "66",
  "ini": "さ",
  "name": "鮫！鮫！鮫！",
  "maker": "東亜プラン",
  "releaseDate": "1990/11/2",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "67",
  "ini": "く",
  "name": "グラナダ",
  "maker": "ウルフチーム",
  "releaseDate": "1990/11/16",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "68",
  "ini": "あ",
  "name": "アイラブミッキーマウス ふしぎのお城 大冒険",
  "maker": "セガ",
  "releaseDate": "1990/11/21",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "69",
  "ini": "め",
  "name": "メガパネル",
  "maker": "ナムコ",
  "releaseDate": "1990/11/22",
  "price": "4900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "70",
  "ini": "し",
  "name": "ジャンクション",
  "maker": "マイクロネット",
  "releaseDate": "1990/11/25",
  "price": "6000",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "71",
  "ini": "し",
  "name": "シャドーダンサー THE SECRET OF SHINOBI",
  "maker": "セガ",
  "releaseDate": "1990/12/1",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "72",
  "ini": "き",
  "name": "キューティー鈴木のリングサイドエンジェル",
  "maker": "アスミック",
  "releaseDate": "1990/12/12",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "73",
  "ini": "あ",
  "name": "アトミックロボキッド",
  "maker": "トレコ",
  "releaseDate": "1990/12/14",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "74",
  "ini": "え",
  "name": "エレメンタルマスター",
  "maker": "テクノソフト",
  "releaseDate": "1990/12/14",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "75",
  "ini": "き",
  "name": "ぎゅわんぶらあ自己中心派　片山まさゆきの麻雀道場",
  "maker": "ゲームアーツ",
  "releaseDate": "1990/12/14",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "76",
  "ini": "ま",
  "name": "まじかるハットのぶっとびターボ！大冒険",
  "maker": "セガ",
  "releaseDate": "1990/12/15",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "77",
  "ini": "て",
  "name": "デンジャラスシード",
  "maker": "ナムコ",
  "releaseDate": "1990/12/18",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "78",
  "ini": "く",
  "name": "クラックダウン",
  "maker": "セガ",
  "releaseDate": "1990/12/20",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "79",
  "ini": "た",
  "name": "ダライアスⅡ",
  "maker": "タイトー",
  "releaseDate": "1990/12/20",
  "price": "8900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "80",
  "ini": "す",
  "name": "スタークルーザー",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1990/12/21",
  "price": "7300",
  "genre": "アドベンチャー・シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "81",
  "ini": "は",
  "name": "ハードドライビン",
  "maker": "テンゲン",
  "releaseDate": "1990/12/21",
  "price": "5900",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "82",
  "ini": "む",
  "name": "武者アレスタ",
  "maker": "東亜プラン",
  "releaseDate": "1990/12/21",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "83",
  "ini": "わ",
  "name": "ワンダーボーイⅢ モンスターレア",
  "maker": "セガ",
  "releaseDate": "1990/12/22",
  "price": "6000",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "84",
  "ini": "か",
  "name": "ガイアレス",
  "maker": "日本テレネット",
  "releaseDate": "1990/12/26",
  "price": "8400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "85",
  "ini": "へ",
  "name": "ヘビーユニット メガドライブ・スペシャル",
  "maker": "東宝",
  "releaseDate": "1990/12/26",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "86",
  "ini": "け",
  "name": "ゲイングランド",
  "maker": "セガ",
  "releaseDate": "1991/1/3",
  "price": "6000",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "87",
  "ini": "う",
  "name": "ヴォルフィード",
  "maker": "タイトー",
  "releaseDate": "1991/1/25",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "88",
  "ini": "し",
  "name": "ジノーグ",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1991/1/25",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "89",
  "ini": "え",
  "name": "エアロブラスターズ",
  "maker": "カネコ",
  "releaseDate": "1991/1/31",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "90",
  "ini": "す",
  "name": "スーパーバレーボール",
  "maker": "ビデオシステム",
  "releaseDate": "1991/2/1",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "91",
  "ini": "れ",
  "name": "レッスルボール",
  "maker": "ナムコ",
  "releaseDate": "1991/2/8",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "92",
  "ini": "は",
  "name": "バトルゴルファー唯",
  "maker": "セガ",
  "releaseDate": "1991/2/15",
  "price": "6000",
  "genre": "スポーツ・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "93",
  "ini": "き",
  "name": "究極タイガー",
  "maker": "トレコ",
  "releaseDate": "1991/2/22",
  "price": "7500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "94",
  "ini": "し",
  "name": "シーザーの野望",
  "maker": "マイクロネット",
  "releaseDate": "1991/2/24",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "95",
  "ini": "し",
  "name": "ジョー・モンタナ フットボール",
  "maker": "セガ",
  "releaseDate": "1991/3/1",
  "price": "6000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "96",
  "ini": "て",
  "name": "ディックトレイシー",
  "maker": "セガ",
  "releaseDate": "1991/3/1",
  "price": "6000",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "97",
  "ini": "は",
  "name": "バハムート戦記",
  "maker": "セガ",
  "releaseDate": "1991/3/8",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "98",
  "ini": "ふ",
  "name": "ふしぎの海のナディア",
  "maker": "ナムコ",
  "releaseDate": "1991/3/19",
  "price": "6500",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "99",
  "ini": "う",
  "name": "ヴァリスⅢ",
  "maker": "日本テレネット",
  "releaseDate": "1991/3/22",
  "price": "8400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "100",
  "ini": "ま",
  "name": "魔物ハンター妖子 第７の警鐘",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1991/3/22",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "101",
  "ini": "し",
  "name": "シャイニング&ザ・ダクネス",
  "maker": "セガ",
  "releaseDate": "1991/3/29",
  "price": "8700",
  "genre": "RPG・3D",
  "icNo": "1"
 },
 {
  "id": "102",
  "ini": "す",
  "name": "スーパーエアーウルフ",
  "maker": "九娯貿易",
  "releaseDate": "1991/3/29",
  "price": "6800",
  "genre": "シューティング・アクション",
  "icNo": "1"
 },
 {
  "id": "103",
  "ini": "み",
  "name": "ミッドナイトレジスタンス",
  "maker": "データイースト",
  "releaseDate": "1991/3/29",
  "price": "7800",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "104",
  "ini": "さ",
  "name": "斬 夜叉円舞曲",
  "maker": "ウルフチーム",
  "releaseDate": "1991/3/29",
  "price": "8500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "105",
  "ini": "し",
  "name": "雀偵物語",
  "maker": "日本テレネット",
  "releaseDate": "1991/3/29",
  "price": "8400",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "106",
  "ini": "う",
  "name": "ヴェリテックス",
  "maker": "アスミック",
  "releaseDate": "1991/4/5",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "107",
  "ini": "ら",
  "name": "ラングリッサー",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1991/4/26",
  "price": "7300",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "108",
  "ini": "わ",
  "name": "ワードナの森SPECIAL",
  "maker": "ビスコ",
  "releaseDate": "1991/4/26",
  "price": "6300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "109",
  "ini": "か",
  "name": "火激",
  "maker": "ホット・ビィ",
  "releaseDate": "1991/4/26",
  "price": "7700",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "110",
  "ini": "し",
  "name": "紫禁城",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1991/4/27",
  "price": "6500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "111",
  "ini": "さ",
  "name": "三国志列伝 乱世の英雄たち",
  "maker": "セガ",
  "releaseDate": "1991/4/29",
  "price": "8700",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "112",
  "ini": "ほ",
  "name": "ボナンザブラザーズ",
  "maker": "セガ",
  "releaseDate": "1991/5/17",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "113",
  "ini": "せ",
  "name": "ゼロウイング",
  "maker": "東亜プラン",
  "releaseDate": "1991/5/31",
  "price": "8000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "114",
  "ini": "ふ",
  "name": "ファイアームスタング",
  "maker": "タイトー",
  "releaseDate": "1991/5/31",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "115",
  "ini": "あ",
  "name": "アークス・オデッセイ",
  "maker": "ウルフチーム",
  "releaseDate": "1991/6/14",
  "price": "8500",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "116",
  "ini": "あ",
  "name": "アドバンスド大戦略 ドイツ電撃作戦",
  "maker": "セガ",
  "releaseDate": "1991/6/17",
  "price": "8700",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "117",
  "ini": "ふ",
  "name": "ブルーアルマナック",
  "maker": "講談社総研",
  "releaseDate": "1991/6/22",
  "price": "8700",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "118",
  "ini": "さ",
  "name": "サンダーフォックス",
  "maker": "タイトー",
  "releaseDate": "1991/6/26",
  "price": "8500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "119",
  "ini": "え",
  "name": "エイリアンストーム",
  "maker": "セガ",
  "releaseDate": "1991/6/28",
  "price": "6000",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "120",
  "ini": "せ",
  "name": "セイントソード",
  "maker": "タイトー",
  "releaseDate": "1991/6/28",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "121",
  "ini": "ふ",
  "name": "ファステスト・ワン",
  "maker": "ヒューマン",
  "releaseDate": "1991/6/28",
  "price": "7500",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "122",
  "ini": "ま",
  "name": "マーベルランド",
  "maker": "ナムコ",
  "releaseDate": "1991/6/28",
  "price": "7000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "123",
  "ini": "れ",
  "name": "レッスルウォー",
  "maker": "セガ",
  "releaseDate": "1991/6/28",
  "price": "6000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "124",
  "ini": "ら",
  "name": "雷電伝説",
  "maker": "マイクロネット",
  "releaseDate": "1991/7/6",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "125",
  "ini": "き",
  "name": "球界道中記",
  "maker": "ナムコ",
  "releaseDate": "1991/7/12",
  "price": "6000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "126",
  "ini": "す",
  "name": "ストリートスマート",
  "maker": "トレコ",
  "releaseDate": "1991/7/19",
  "price": "6800",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "127",
  "ini": "そ",
  "name": "ソニック・ザ・ヘッジホッグ",
  "maker": "セガ",
  "releaseDate": "1991/7/26",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "128",
  "ini": "ま",
  "name": "マスター・オブ・モンスターズ",
  "maker": "東芝EMI",
  "releaseDate": "1991/7/26",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "129",
  "ini": "へ",
  "name": "ベア・ナックル 怒りの鉄拳",
  "maker": "セガ",
  "releaseDate": "1991/8/2",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "130",
  "ini": "く",
  "name": "空牙",
  "maker": "日本テレネット",
  "releaseDate": "1991/8/2",
  "price": "8400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "131",
  "ini": "ち",
  "name": "超闘竜烈伝ディノランド",
  "maker": "ウルフチーム",
  "releaseDate": "1991/8/2",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "132",
  "ini": "め",
  "name": "メガトラックス",
  "maker": "ナムコ",
  "releaseDate": "1991/8/6",
  "price": "6000",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "133",
  "ini": "あ",
  "name": "アウトラン",
  "maker": "セガ",
  "releaseDate": "1991/8/9",
  "price": "7000",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "134",
  "ini": "ほ",
  "name": "ポピュラス",
  "maker": "セガ",
  "releaseDate": "1991/8/9",
  "price": "6000",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "135",
  "ini": "し",
  "name": "ジュエルマスター",
  "maker": "セガ",
  "releaseDate": "1991/8/30",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "136",
  "ini": "ふ",
  "name": "プロ野球スーパーリーグ'91",
  "maker": "セガ",
  "releaseDate": "1991/8/30",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "137",
  "ini": "き",
  "name": "ギャラクシーフォースⅡ",
  "maker": "CRI",
  "releaseDate": "1991/9/13",
  "price": "8400",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "138",
  "ini": "え",
  "name": "エル・ヴィエント",
  "maker": "ウルフチーム",
  "releaseDate": "1991/9/20",
  "price": "8500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "139",
  "ini": "れ",
  "name": "レンタヒーロー",
  "maker": "セガ",
  "releaseDate": "1991/9/20",
  "price": "8700",
  "genre": "RPG・アクション",
  "icNo": "1"
 },
 {
  "id": "140",
  "ini": "ま",
  "name": "マスターオブウエポン",
  "maker": "タイトー",
  "releaseDate": "1991/9/27",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "141",
  "ini": "せ",
  "name": "戦場の狼Ⅱ",
  "maker": "セガ",
  "releaseDate": "1991/9/27",
  "price": "7000",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "142",
  "ini": "う",
  "name": "宇宙戦艦ゴモラ",
  "maker": "UPL",
  "releaseDate": "1991/9/30",
  "price": "7500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "143",
  "ini": "て",
  "name": "デビルクラッシュMD",
  "maker": "テクノソフト",
  "releaseDate": "1991/10/10",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "144",
  "ini": "そ",
  "name": "ソード・オブ・ソダン",
  "maker": "セガ",
  "releaseDate": "1991/10/11",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "145",
  "ini": "す",
  "name": "スパイダーマン",
  "maker": "セガ",
  "releaseDate": "1991/10/18",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "146",
  "ini": "わ",
  "name": "ワンダーボーイV モンスターワールドⅢ",
  "maker": "セガ",
  "releaseDate": "1991/10/25",
  "price": "7000",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "147",
  "ini": "ま",
  "name": "魔王連獅子",
  "maker": "タイトー",
  "releaseDate": "1991/10/25",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "148",
  "ini": "し",
  "name": "将棋の星",
  "maker": "ホームデータ",
  "releaseDate": "1991/10/31",
  "price": "6700",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "149",
  "ini": "ふ",
  "name": "ブロックアウト",
  "maker": "セガ",
  "releaseDate": "1991/11/1",
  "price": "6000",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "150",
  "ini": "わ",
  "name": "ワンダラーズ フロム イース",
  "maker": "日本テレネット",
  "releaseDate": "1991/11/1",
  "price": "8700",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "151",
  "ini": "と",
  "name": "ドラゴンズアイ プラス 上海Ⅲ",
  "maker": "ホームデータ",
  "releaseDate": "1991/11/2",
  "price": "5800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "152",
  "ini": "る",
  "name": "ルナーク",
  "maker": "タイトー",
  "releaseDate": "1991/11/15",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "153",
  "ini": "ろ",
  "name": "ローリングサンダー2",
  "maker": "ナムコ",
  "releaseDate": "1991/11/19",
  "price": "7000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "154",
  "ini": "ふ",
  "name": "ファンタジア ミッキーマウス・マジック",
  "maker": "セガ",
  "releaseDate": "1991/11/22",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "155",
  "ini": "ひ",
  "name": "ビースト・ウォリアーズ",
  "maker": "日本テレネット",
  "releaseDate": "1991/11/29",
  "price": "8400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "156",
  "ini": "ら",
  "name": "乱世の覇者",
  "maker": "アスミック",
  "releaseDate": "1991/11/29",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "157",
  "ini": "に",
  "name": "忍者武雷伝説",
  "maker": "セガ",
  "releaseDate": "1991/12/5",
  "price": "8700",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "158",
  "ini": "え",
  "name": "エグザイル 時の狭間へ",
  "maker": "日本テレネット",
  "releaseDate": "1991/12/6",
  "price": "8900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "159",
  "ini": "ふ",
  "name": "ファイティングマスターズ",
  "maker": "トレコ",
  "releaseDate": "1991/12/6",
  "price": "6800",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "160",
  "ini": "そ",
  "name": "ソル・フィース",
  "maker": "ウルフチーム",
  "releaseDate": "1991/12/12",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "161",
  "ini": "へ",
  "name": "ヘビーノバ",
  "maker": "マイクロネット",
  "releaseDate": "1991/12/12",
  "price": "6800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "162",
  "ini": "た",
  "name": "太平記",
  "maker": "セガ",
  "releaseDate": "1991/12/13",
  "price": "8700",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "163",
  "ini": "の",
  "name": "ノスタルジア1907",
  "maker": "シュールド・ウェーブ",
  "releaseDate": "1991/12/14",
  "price": "7200",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "164",
  "ini": "え",
  "name": "F1サーカスMD",
  "maker": "日本物産",
  "releaseDate": "1991/12/20",
  "price": "7500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "165",
  "ini": "あ",
  "name": "アイラブドナルドダック グルジア王の秘宝",
  "maker": "セガ",
  "releaseDate": "1991/12/20",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "166",
  "ini": "あ",
  "name": "アンデッドライン",
  "maker": "PALSOFT",
  "releaseDate": "1991/12/20",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "167",
  "ini": "た",
  "name": "ダーナ 女神誕生",
  "maker": "IGS",
  "releaseDate": "1991/12/20",
  "price": "8900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "168",
  "ini": "た",
  "name": "タスクフォースハリアーEX",
  "maker": "トレコ",
  "releaseDate": "1991/12/20",
  "price": "8500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "169",
  "ini": "た",
  "name": "ダブルドラゴンⅡ",
  "maker": "PALSOFT",
  "releaseDate": "1991/12/20",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "170",
  "ini": "の",
  "name": "信長の野望 武将風雲録",
  "maker": "光栄",
  "releaseDate": "1991/12/20",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "171",
  "ini": "な",
  "name": "中島悟監修　F-1 GRAND PRIX",
  "maker": "バリエ",
  "releaseDate": "1991/12/20",
  "price": "7800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "172",
  "ini": "あ",
  "name": "アーネスト・エバンス",
  "maker": "ウルフチーム",
  "releaseDate": "1991/12/20",
  "price": "7300",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "173",
  "ini": "わ",
  "name": "惑星ウッドストック ファンキーホラーバンド",
  "maker": "セガ",
  "releaseDate": "1991/12/20",
  "price": "6800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "174",
  "ini": "さ",
  "name": "三國志Ⅱ",
  "maker": "光栄",
  "releaseDate": "1991/12/26",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "175",
  "ini": "こ",
  "name": "ゴールデンアックスⅡ",
  "maker": "セガ",
  "releaseDate": "1991/12/27",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "176",
  "ini": "む",
  "name": "夢幻戦士ヴァリス",
  "maker": "日本テレネット",
  "releaseDate": "1991/12/27",
  "price": "8400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "177",
  "ini": "て",
  "name": "天下布武 英雄たちの咆哮",
  "maker": "ゲームアーツ",
  "releaseDate": "1991/12/28",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "178",
  "ini": "す",
  "name": "スーパーファンタジーゾーン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/1/14",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "179",
  "ini": "ち",
  "name": "ちびまる子ちゃん わくわくショッピング",
  "maker": "ナムコ",
  "releaseDate": "1992/1/14",
  "price": "6000",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "180",
  "ini": "し",
  "name": "ジョー・モンタナⅡ スポーツトークフットボール",
  "maker": "セガ",
  "releaseDate": "1992/1/24",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "181",
  "ini": "し",
  "name": "ＪｕＪｕ伝説",
  "maker": "セガ",
  "releaseDate": "1992/1/31",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "182",
  "ini": "て",
  "name": "テクモワールドカップ'92",
  "maker": "シムス",
  "releaseDate": "1992/1/31",
  "price": "6200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "183",
  "ini": "わ",
  "name": "ワニワニWorld",
  "maker": "カネコ",
  "releaseDate": "1992/1/31",
  "price": "6600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "184",
  "ini": "そ",
  "name": "ソーサルキングダム",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/2/7",
  "price": "8800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "185",
  "ini": "え",
  "name": "SDヴァリス",
  "maker": "日本テレネット",
  "releaseDate": "1992/2/14",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "186",
  "ini": "み",
  "name": "港のトレイジア",
  "maker": "日本テレネット",
  "releaseDate": "1992/2/14",
  "price": "8900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "187",
  "ini": "せ",
  "name": "精霊神世紀フェイエリア",
  "maker": "ウルフチーム",
  "releaseDate": "1992/2/18",
  "price": "7400",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "188",
  "ini": "く",
  "name": "クルードバスター",
  "maker": "データイースト",
  "releaseDate": "1992/2/28",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "189",
  "ini": "ろ",
  "name": "ロードブラスターズ",
  "maker": "テンゲン",
  "releaseDate": "1992/2/28",
  "price": "6800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "190",
  "ini": "は",
  "name": "バトルマニア",
  "maker": "ビック東海",
  "releaseDate": "1992/3/6",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "191",
  "ini": "と",
  "name": "トージャム&アール",
  "maker": "セガ",
  "releaseDate": "1992/3/13",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "192",
  "ini": "こ",
  "name": "鋼鉄帝国",
  "maker": "ホット・ビィ",
  "releaseDate": "1992/3/13",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "193",
  "ini": "し",
  "name": "シャイニング・フォース 神々の遺産",
  "maker": "セガ",
  "releaseDate": "1992/3/20",
  "price": "8700",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "194",
  "ini": "あ",
  "name": "アートアライブ",
  "maker": "セガ",
  "releaseDate": "1992/3/27",
  "price": "3800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "195",
  "ini": "さ",
  "name": "サンダープロレスリング列伝",
  "maker": "ヒューマン",
  "releaseDate": "1992/3/27",
  "price": "7400",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "196",
  "ini": "し",
  "name": "シャドー・オブ・ザ・ビースト 魔性の掟",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/3/27",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "197",
  "ini": "す",
  "name": "ストームロード",
  "maker": "MCW",
  "releaseDate": "1992/3/27",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "198",
  "ini": "た",
  "name": "ターボアウトラン",
  "maker": "セガ",
  "releaseDate": "1992/3/27",
  "price": "6000",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "199",
  "ini": "ひ",
  "name": "ピットファイター",
  "maker": "テンゲン",
  "releaseDate": "1992/3/27",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "200",
  "ini": "こ",
  "name": "コズミックファンタジー Stories",
  "maker": "日本テレネット",
  "releaseDate": "1992/3/27",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "201",
  "ini": "え",
  "name": "A列車で行こうMD",
  "maker": "セガ",
  "releaseDate": "1992/4/10",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "202",
  "ini": "て",
  "name": "デスブリンガー 秘められた紋章",
  "maker": "日本テレネット",
  "releaseDate": "1992/4/17",
  "price": "7400",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "203",
  "ini": "あ",
  "name": "アリシアドラグーン",
  "maker": "ゲームアーツ",
  "releaseDate": "1992/4/24",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "204",
  "ini": "は",
  "name": "バッドオーメン",
  "maker": "ホット・ビィ",
  "releaseDate": "1992/4/24",
  "price": "6800",
  "genre": "ブロック崩し",
  "icNo": "1"
 },
 {
  "id": "205",
  "ini": "ま",
  "name": "まじかる☆タルるートくん",
  "maker": "セガ",
  "releaseDate": "1992/4/24",
  "price": "3880",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "206",
  "ini": "た",
  "name": "大航海時代",
  "maker": "光栄",
  "releaseDate": "1992/4/29",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "207",
  "ini": "す",
  "name": "スライムワールド",
  "maker": "MCW",
  "releaseDate": "1992/4/30",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "208",
  "ini": "な",
  "name": "中島悟監修 F1 HERO MD",
  "maker": "バリエ",
  "releaseDate": "1992/5/15",
  "price": "7800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "209",
  "ini": "し",
  "name": "シーザーの野望Ⅱ",
  "maker": "マイクロネット",
  "releaseDate": "1992/5/28",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "210",
  "ini": "か",
  "name": "カメレオンキッド",
  "maker": "セガ",
  "releaseDate": "1992/5/29",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "211",
  "ini": "あ",
  "name": "アイルロード",
  "maker": "ウルフチーム",
  "releaseDate": "1992/5/29",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "212",
  "ini": "く",
  "name": "クイズスクランブル スペシャル",
  "maker": "セガ",
  "releaseDate": "1992/5/29",
  "price": "6800",
  "genre": "クイズ",
  "icNo": "5"
 },
 {
  "id": "213",
  "ini": "く",
  "name": "グランドスラム",
  "maker": "日本テレネット",
  "releaseDate": "1992/6/12",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "214",
  "ini": "と",
  "name": "TOP PRO GOLF",
  "maker": "ソフトビジョン",
  "releaseDate": "1992/6/19",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "215",
  "ini": "す",
  "name": "スピードボール2",
  "maker": "CRI",
  "releaseDate": "1992/6/19",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "216",
  "ini": "ま",
  "name": "魔法の少女シルキーリップ",
  "maker": "日本テレネット",
  "releaseDate": "1992/6/19",
  "price": "7400",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "217",
  "ini": "ろ",
  "name": "ロイヤルブラッド",
  "maker": "光栄",
  "releaseDate": "1992/6/25",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "218",
  "ini": "へ",
  "name": "ペーパーボーイ",
  "maker": "テンゲン",
  "releaseDate": "1992/6/26",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "219",
  "ini": "と",
  "name": "闘技王キングコロッサス",
  "maker": "セガ",
  "releaseDate": "1992/6/26",
  "price": "5800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "220",
  "ini": "る",
  "name": "ルナ ザ・シルバースター",
  "maker": "ゲームアーツ",
  "releaseDate": "1992/6/26",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "221",
  "ini": "て",
  "name": "デビッド・ロビンソン バスケットボール",
  "maker": "セガ",
  "releaseDate": "1992/7/10",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "222",
  "ini": "ほ",
  "name": "炎の闘球児ドッジ弾平",
  "maker": "セガ",
  "releaseDate": "1992/7/10",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "223",
  "ini": "あ",
  "name": "アイルトン・セナ スーパーモナコGPⅡ",
  "maker": "セガ",
  "releaseDate": "1992/7/17",
  "price": "7800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "224",
  "ini": "く",
  "name": "グレイランサー",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/7/17",
  "price": "8300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "225",
  "ini": "お",
  "name": "オリンピックゴールド",
  "maker": "セガ",
  "releaseDate": "1992/7/24",
  "price": "4800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "226",
  "ini": "さ",
  "name": "サンダーフォースⅣ",
  "maker": "テクノソフト",
  "releaseDate": "1992/7/24",
  "price": "8800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "227",
  "ini": "た",
  "name": "ダイナブラザーズ",
  "maker": "CRI",
  "releaseDate": "1992/7/24",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "228",
  "ini": "つ",
  "name": "ツインクルテール",
  "maker": "WAS",
  "releaseDate": "1992/7/24",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "229",
  "ini": "て",
  "name": "デトネイター・オーガン",
  "maker": "ホット・ビィ",
  "releaseDate": "1992/7/31",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "230",
  "ini": "す",
  "name": "スプラッターハウス PART2",
  "maker": "ナムコ",
  "releaseDate": "1992/8/4",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "231",
  "ini": "ひ",
  "name": "ひょっこりひょうたん島 大統領をめざせ！",
  "maker": "セガ",
  "releaseDate": "1992/8/7",
  "price": "4800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "232",
  "ini": "し",
  "name": "修羅の門",
  "maker": "セガ",
  "releaseDate": "1992/8/7",
  "price": "6000",
  "genre": "アニメーションバトル",
  "icNo": "1"
 },
 {
  "id": "233",
  "ini": "ね",
  "name": "熱血高校ドッジボール部 サッカー編MD",
  "maker": "PALSOFT",
  "releaseDate": "1992/8/7",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "234",
  "ini": "ふ",
  "name": "プリンス・オブ・ペルシャ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/8/7",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "235",
  "ini": "さ",
  "name": "サンダーストームFX",
  "maker": "ウルフチーム",
  "releaseDate": "1992/8/28",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "236",
  "ini": "ふ",
  "name": "ブライ 八玉の勇士伝説",
  "maker": "セガ",
  "releaseDate": "1992/9/11",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "237",
  "ini": "て",
  "name": "提督の決断",
  "maker": "光栄",
  "releaseDate": "1992/9/24",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "238",
  "ini": "ら",
  "name": "ライズ オブ ザ ドラゴン",
  "maker": "セガ",
  "releaseDate": "1992/9/25",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "239",
  "ini": "わ",
  "name": "ワンダードッグ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1992/9/25",
  "price": "7200",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "240",
  "ini": "き",
  "name": "キングサーモン",
  "maker": "ホット・ビィ",
  "releaseDate": "1992/9/26",
  "price": "6800",
  "genre": "釣り・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "241",
  "ini": "ち",
  "name": "チェルノブ",
  "maker": "データイースト",
  "releaseDate": "1992/10/16",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "242",
  "ini": "ち",
  "name": "チキチキボーイズ",
  "maker": "セガ",
  "releaseDate": "1992/10/16",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "243",
  "ini": "う",
  "name": "ヴィクセン357",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1992/10/23",
  "price": "8800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "244",
  "ini": "す",
  "name": "スーパーH.Q.",
  "maker": "タイトー",
  "releaseDate": "1992/10/23",
  "price": "6800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "245",
  "ini": "き",
  "name": "機動警察パトレイバー 98式起動せよ！",
  "maker": "マーバ",
  "releaseDate": "1992/10/23",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "246",
  "ini": "ふ",
  "name": "ブラックホールアサルト",
  "maker": "マイクロネット",
  "releaseDate": "1992/10/23",
  "price": "6800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "247",
  "ini": "く",
  "name": "クライング 亜生命戦争",
  "maker": "セガ",
  "releaseDate": "1992/10/30",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "248",
  "ini": "ほ",
  "name": "ホリフィールドボクシング",
  "maker": "セガ",
  "releaseDate": "1992/10/30",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "249",
  "ini": "ら",
  "name": "ランドストーカー 皇帝の財宝",
  "maker": "セガ",
  "releaseDate": "1992/10/30",
  "price": "8700",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "250",
  "ini": "ふ",
  "name": "プロ野球スーパーリーグCD",
  "maker": "セガ",
  "releaseDate": "1992/10/30",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "251",
  "ini": "え",
  "name": "エアーマネジメント 大空に賭ける",
  "maker": "光栄",
  "releaseDate": "1992/11/1",
  "price": "11800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "252",
  "ini": "さ",
  "name": "三國志Ⅲ",
  "maker": "光栄",
  "releaseDate": "1992/11/8",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "253",
  "ini": "た",
  "name": "タイムギャル",
  "maker": "ウルフチーム",
  "releaseDate": "1992/11/13",
  "price": "7800",
  "genre": "アニメーション・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "254",
  "ini": "ふ",
  "name": "プロフットボール",
  "maker": "EAV",
  "releaseDate": "1992/11/20",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "255",
  "ini": "ふ",
  "name": "プロホッケー",
  "maker": "EAV",
  "releaseDate": "1992/11/20",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "256",
  "ini": "れ",
  "name": "レミングス",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/11/20",
  "price": "7800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "257",
  "ini": "ろ",
  "name": "ロードラッシュ",
  "maker": "EAV",
  "releaseDate": "1992/11/20",
  "price": "7800",
  "genre": "レース・3D",
  "icNo": "2"
 },
 {
  "id": "258",
  "ini": "そ",
  "name": "ソニック・ザ・ヘッジホッグ2",
  "maker": "セガ",
  "releaseDate": "1992/11/21",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "259",
  "ini": "て",
  "name": "電忍アレスタ",
  "maker": "コンパイル",
  "releaseDate": "1992/11/27",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "260",
  "ini": "さ",
  "name": "サイドポケット",
  "maker": "データイースト",
  "releaseDate": "1992/12/11",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "261",
  "ini": "は",
  "name": "パワーアスリート",
  "maker": "カネコ",
  "releaseDate": "1992/12/11",
  "price": "8500",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "262",
  "ini": "ら",
  "name": "ランパート",
  "maker": "テンゲン",
  "releaseDate": "1992/12/11",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "263",
  "ini": "な",
  "name": "中島悟監修 F-1 スーパーライセンス",
  "maker": "バリエ",
  "releaseDate": "1992/12/11",
  "price": "9000",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "264",
  "ini": "あ",
  "name": "R.B.I.4.ベースボール",
  "maker": "テンゲン",
  "releaseDate": "1992/12/18",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "265",
  "ini": "あ",
  "name": "アイラブミッキー＆ドナルド ふしぎなマジックボックス",
  "maker": "セガ",
  "releaseDate": "1992/12/18",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "266",
  "ini": "は",
  "name": "パチンコクーニャン",
  "maker": "S・I",
  "releaseDate": "1992/12/18",
  "price": "8500",
  "genre": "パチンコ",
  "icNo": "1"
 },
 {
  "id": "267",
  "ini": "ふ",
  "name": "ぷよぷよ",
  "maker": "セガ",
  "releaseDate": "1992/12/18",
  "price": "4800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "268",
  "ini": "あ",
  "name": "アフターバーナーⅢ",
  "maker": "CRI",
  "releaseDate": "1992/12/18",
  "price": "8400",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "269",
  "ini": "ろ",
  "name": "ロードブラスターFX",
  "maker": "ウルフチーム",
  "releaseDate": "1992/12/18",
  "price": "7800",
  "genre": "アニメーション・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "270",
  "ini": "き",
  "name": "ぎゅわんぶらあ自己中心派2 激闘!東京マージャンランド編",
  "maker": "ゲームアーツ",
  "releaseDate": "1992/12/18",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "271",
  "ini": "て",
  "name": "T.M.N.T. リターン オブ ザ シュレッダー",
  "maker": "コナミ",
  "releaseDate": "1992/12/22",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "272",
  "ini": "た",
  "name": "タズマニア",
  "maker": "セガ",
  "releaseDate": "1992/12/25",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "273",
  "ini": "て",
  "name": "天舞メガCDスペシャル",
  "maker": "ウルフチーム",
  "releaseDate": "1992/12/25",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "274",
  "ini": "か",
  "name": "カプコンのクイズ殿様の野望",
  "maker": "シムス",
  "releaseDate": "1992/12/25",
  "price": "7800",
  "genre": "クイズ",
  "icNo": "5"
 },
 {
  "id": "275",
  "ini": "と",
  "name": "ドリームチームUSA",
  "maker": "EAV",
  "releaseDate": "1992/12/26",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "276",
  "ini": "さ",
  "name": "サイキック・ディテクティブ・シリーズVol.3 AYA",
  "maker": "データウエスト",
  "releaseDate": "1993/1/3",
  "price": "7600",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "277",
  "ini": "へ",
  "name": "ベア・ナックルⅡ 死闘への鎮魂歌",
  "maker": "セガ",
  "releaseDate": "1993/1/14",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "278",
  "ini": "よ",
  "name": "ヨーロッパ戦線",
  "maker": "光栄",
  "releaseDate": "1993/1/16",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "279",
  "ini": "さ",
  "name": "ザ・キックボクシング",
  "maker": "MCW",
  "releaseDate": "1993/1/29",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "280",
  "ini": "ゆ",
  "name": "ゆみみみっくす",
  "maker": "ゲームアーツ",
  "releaseDate": "1993/1/29",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "281",
  "ini": "え",
  "name": "F22インターセプター",
  "maker": "EAV",
  "releaseDate": "1993/2/12",
  "price": "8900",
  "genre": "シューティング・3D",
  "icNo": "2"
 },
 {
  "id": "282",
  "ini": "は",
  "name": "バットマン・リターンズ",
  "maker": "セガ",
  "releaseDate": "1993/2/19",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "283",
  "ini": "し",
  "name": "邪神ドラクソス",
  "maker": "EAV",
  "releaseDate": "1993/2/19",
  "price": "8900",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "284",
  "ini": "し",
  "name": "G-LOC",
  "maker": "セガ",
  "releaseDate": "1993/2/26",
  "price": "6800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "285",
  "ini": "し",
  "name": "Ｊリーグチャンピオンサッカー",
  "maker": "小学館プロ・ゲームアーツ",
  "releaseDate": "1993/2/26",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "286",
  "ini": "ま",
  "name": "マジンサーガ",
  "maker": "セガ",
  "releaseDate": "1993/2/26",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "287",
  "ini": "し",
  "name": "シムアース",
  "maker": "セガ",
  "releaseDate": "1993/3/12",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "288",
  "ini": "に",
  "name": "ニンジャウォーリアーズ",
  "maker": "タイトー",
  "releaseDate": "1993/3/12",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "289",
  "ini": "す",
  "name": "スプラッターハウス PART3",
  "maker": "ナムコ",
  "releaseDate": "1993/3/19",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "290",
  "ini": "う",
  "name": "ウルフチャイルド",
  "maker": "ビクター音楽産業",
  "releaseDate": "1993/3/19",
  "price": "8800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "291",
  "ini": "あ",
  "name": "蒼き狼と白き牝鹿 元朝秘史",
  "maker": "光栄",
  "releaseDate": "1993/3/25",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "292",
  "ini": "こ",
  "name": "GODS",
  "maker": "PCM",
  "releaseDate": "1993/3/26",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "293",
  "ini": "あ",
  "name": "OutRun 2019",
  "maker": "シムス",
  "releaseDate": "1993/3/26",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "294",
  "ini": "と",
  "name": "ドラえもん 夢どろぼうと７人のゴザンス",
  "maker": "セガ",
  "releaseDate": "1993/3/26",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "295",
  "ini": "は",
  "name": "バトルトード",
  "maker": "セガ",
  "releaseDate": "1993/3/26",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "296",
  "ini": "し",
  "name": "ジャガーXJ220",
  "maker": "ビクター音楽産業",
  "releaseDate": "1993/3/26",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "5"
 },
 {
  "id": "297",
  "ini": "あ",
  "name": "アネット再び",
  "maker": "ウルフチーム",
  "releaseDate": "1993/3/30",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "298",
  "ini": "え",
  "name": "NBAプロバスケットボール　ブルズVSレイカーズ",
  "maker": "EAV",
  "releaseDate": "1993/4/2",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "299",
  "ini": "ふ",
  "name": "ファイナルファイトCD",
  "maker": "セガ",
  "releaseDate": "1993/4/2",
  "price": "8800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "300",
  "ini": "う",
  "name": "ウルトラマン",
  "maker": "マーバ",
  "releaseDate": "1993/4/9",
  "price": "5800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "301",
  "ini": "ひ",
  "name": "PGAツアーゴルフⅡ",
  "maker": "EAV",
  "releaseDate": "1993/4/16",
  "price": "9800",
  "genre": "スポーツ・3D",
  "icNo": "2"
 },
 {
  "id": "302",
  "ini": "て",
  "name": "デザートストライク 湾岸作戦",
  "maker": "EAV",
  "releaseDate": "1993/4/23",
  "price": "8900",
  "genre": "シューティング",
  "icNo": "2"
 },
 {
  "id": "303",
  "ini": "ほ",
  "name": "ボールジャックス",
  "maker": "ナムコ",
  "releaseDate": "1993/4/23",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "304",
  "ini": "め",
  "name": "メガロマニア",
  "maker": "CRI",
  "releaseDate": "1993/4/23",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "305",
  "ini": "か",
  "name": "餓狼伝説 宿命の戦い",
  "maker": "セガ",
  "releaseDate": "1993/4/23",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "306",
  "ini": "さ",
  "name": "三國志Ⅲ",
  "maker": "光栄",
  "releaseDate": "1993/4/23",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "307",
  "ini": "せ",
  "name": "SEGA CLASSIC ARCADE COLLECTION",
  "maker": "セガ",
  "releaseDate": "1993/4/23",
  "price": "2980",
  "genre": "アクション・パズル",
  "icNo": "5"
 },
 {
  "id": "308",
  "ini": "す",
  "name": "SWITCH",
  "maker": "セガ",
  "releaseDate": "1993/4/23",
  "price": "8800",
  "genre": "その他",
  "icNo": "5"
 },
 {
  "id": "309",
  "ini": "ら",
  "name": "らんま1/2 白蘭愛歌",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1993/4/23",
  "price": "8300",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "310",
  "ini": "そ",
  "name": "ゾウ！ゾウ！ゾウ！ レスキュー大作戦",
  "maker": "EAV",
  "releaseDate": "1993/4/29",
  "price": "8900",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "311",
  "ini": "せ",
  "name": "００７・死闘",
  "maker": "テンゲン",
  "releaseDate": "1993/5/14",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "312",
  "ini": "え",
  "name": "エクスランザー",
  "maker": "セガ",
  "releaseDate": "1993/5/28",
  "price": "7800",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "313",
  "ini": "す",
  "name": "スノーブラザーズ",
  "maker": "テンゲン",
  "releaseDate": "1993/5/28",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "314",
  "ini": "た",
  "name": "太閤立志伝",
  "maker": "光栄",
  "releaseDate": "1993/5/28",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "315",
  "ini": "て",
  "name": "デバステイター",
  "maker": "ウルフチーム",
  "releaseDate": "1993/5/28",
  "price": "7800",
  "genre": "アクション・シューティング",
  "icNo": "5"
 },
 {
  "id": "316",
  "ini": "な",
  "name": "ナイトストライカー",
  "maker": "タイトー",
  "releaseDate": "1993/5/28",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "317",
  "ini": "け",
  "name": "幻影都市 ILLUSION CITY",
  "maker": "マイクロキャビン",
  "releaseDate": "1993/5/28",
  "price": "4980",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "318",
  "ini": "え",
  "name": "LHXアタックチョッパー",
  "maker": "EAV",
  "releaseDate": "1993/6/4",
  "price": "6800",
  "genre": "シューティング・3D",
  "icNo": "2"
 },
 {
  "id": "319",
  "ini": "し",
  "name": "ジェームスポンドⅡ コードネーム・ロボコッド",
  "maker": "EAV",
  "releaseDate": "1993/6/9",
  "price": "6900",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "320",
  "ini": "す",
  "name": "スラップファイト",
  "maker": "テンゲン",
  "releaseDate": "1993/6/11",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "321",
  "ini": "し",
  "name": "Ｊリーグ プロストライカー",
  "maker": "セガ",
  "releaseDate": "1993/6/18",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "322",
  "ini": "は",
  "name": "パワーモンガー",
  "maker": "EAV",
  "releaseDate": "1993/6/18",
  "price": "8900",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "323",
  "ini": "と",
  "name": "TOP PRO GOLF2",
  "maker": "S・I",
  "releaseDate": "1993/6/25",
  "price": "8500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "324",
  "ini": "え",
  "name": "エリミネートダウン",
  "maker": "S・I",
  "releaseDate": "1993/6/25",
  "price": "8500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "325",
  "ini": "こ",
  "name": "ゴールデンアックスⅢ",
  "maker": "セガ",
  "releaseDate": "1993/6/25",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "326",
  "ini": "す",
  "name": "スティールタロンズ",
  "maker": "テンゲン",
  "releaseDate": "1993/6/25",
  "price": "6800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "327",
  "ini": "め",
  "name": "メガシュヴァルツシルト",
  "maker": "セガ",
  "releaseDate": "1993/6/25",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "328",
  "ini": "え",
  "name": "Aランクサンダー 誕生編",
  "maker": "RIOT",
  "releaseDate": "1993/6/25",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "329",
  "ini": "た",
  "name": "ダイナミックカントリークラブ",
  "maker": "セガ",
  "releaseDate": "1993/7/16",
  "price": "7800",
  "genre": "スポーツ・3D",
  "icNo": "5"
 },
 {
  "id": "330",
  "ini": "さ",
  "name": "ザ・スーパー忍Ⅱ",
  "maker": "セガ",
  "releaseDate": "1993/7/23",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "331",
  "ini": "ろ",
  "name": "ロードラッシュⅡ",
  "maker": "EAV",
  "releaseDate": "1993/7/23",
  "price": "8900",
  "genre": "レース・3D",
  "icNo": "2"
 },
 {
  "id": "332",
  "ini": "あ",
  "name": "アークスⅠ・Ⅱ・Ⅲ",
  "maker": "ウルフチーム",
  "releaseDate": "1993/7/23",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "333",
  "ini": "せ",
  "name": "聖魔伝説3×3EYES",
  "maker": "セガ",
  "releaseDate": "1993/7/23",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "334",
  "ini": "え",
  "name": "NBAプレイオフ　ブルズVSブレイザーズ",
  "maker": "EAV",
  "releaseDate": "1993/7/30",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "335",
  "ini": "え",
  "name": "エコー・ザ・ドルフィン",
  "maker": "セガ",
  "releaseDate": "1993/7/30",
  "price": "6800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "336",
  "ini": "き",
  "name": "騎士伝説",
  "maker": "講談社総研",
  "releaseDate": "1993/7/30",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "337",
  "ini": "し",
  "name": "シルフィード",
  "maker": "ゲームアーツ",
  "releaseDate": "1993/7/30",
  "price": "8800",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "338",
  "ini": "は",
  "name": "バリ・アーム",
  "maker": "ヒューマン",
  "releaseDate": "1993/7/30",
  "price": "7500",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "339",
  "ini": "さ",
  "name": "サイボーグ009",
  "maker": "RIOT",
  "releaseDate": "1993/7/30",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "340",
  "ini": "ろ",
  "name": "ロケットナイト　アドベンチャーズ",
  "maker": "コナミ",
  "releaseDate": "1993/8/6",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "341",
  "ini": "え",
  "name": "江川卓のスーパーリーグCD",
  "maker": "セガ",
  "releaseDate": "1993/8/6",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "342",
  "ini": "け",
  "name": "慶応遊撃隊",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1993/8/6",
  "price": "7400",
  "genre": "シューティング",
  "icNo": "5"
 },
 {
  "id": "343",
  "ini": "う",
  "name": "ウィザード・オブ・イモータル",
  "maker": "EAV",
  "releaseDate": "1993/8/10",
  "price": "9800",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "344",
  "ini": "ま",
  "name": "マーブルマッドネス",
  "maker": "テンゲン",
  "releaseDate": "1993/8/13",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "345",
  "ini": "き",
  "name": "キリング・ゲームショー",
  "maker": "EAV",
  "releaseDate": "1993/8/20",
  "price": "8900",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "346",
  "ini": "し",
  "name": "ジュラシック・パーク",
  "maker": "セガ",
  "releaseDate": "1993/8/27",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "347",
  "ini": "し",
  "name": "雀豪ワールドカップ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1993/8/27",
  "price": "8200",
  "genre": "テーブル",
  "icNo": "5"
 },
 {
  "id": "348",
  "ini": "あ",
  "name": "ああ播磨灘",
  "maker": "セガ",
  "releaseDate": "1993/9/3",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "349",
  "ini": "か",
  "name": "ガンスターヒーローズ",
  "maker": "セガ",
  "releaseDate": "1993/9/10",
  "price": "6800",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "350",
  "ini": "み",
  "name": "ミュータントリーグ・フットボール",
  "maker": "EAV",
  "releaseDate": "1993/9/10",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "351",
  "ini": "の",
  "name": "信長の野望 全国版",
  "maker": "光栄",
  "releaseDate": "1993/9/15",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "352",
  "ini": "か",
  "name": "ガントレット",
  "maker": "テンゲン",
  "releaseDate": "1993/9/17",
  "price": "7800",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "353",
  "ini": "う",
  "name": "ウイニングポスト",
  "maker": "光栄",
  "releaseDate": "1993/9/17",
  "price": "9800",
  "genre": "競馬・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "354",
  "ini": "わ",
  "name": "笑ゥせぇるすまん",
  "maker": "セガ",
  "releaseDate": "1993/9/17",
  "price": "7800",
  "genre": "アニメーション・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "355",
  "ini": "さ",
  "name": "サンダーホーク",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1993/9/17",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "356",
  "ini": "ま",
  "name": "マクドナルド トレジャーランド・アドベンチャー",
  "maker": "セガ",
  "releaseDate": "1993/9/23",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "357",
  "ini": "そ",
  "name": "ソニック・ザ・ヘッジホッグCD",
  "maker": "セガ",
  "releaseDate": "1993/9/23",
  "price": "8800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "358",
  "ini": "も",
  "name": "モンキー・アイランド ユーレイ海賊大騒動!",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1993/9/23",
  "price": "8800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "359",
  "ini": "し",
  "name": "ジョーダン VS バード ONE on ONE",
  "maker": "EAV",
  "releaseDate": "1993/9/24",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "360",
  "ini": "あ",
  "name": "蒼き狼と白き牝鹿・元朝秘史",
  "maker": "光栄",
  "releaseDate": "1993/9/24",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "361",
  "ini": "す",
  "name": "ストリートファイターⅡダッシュプラス",
  "maker": "カプコン",
  "releaseDate": "1993/9/28",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "362",
  "ini": "し",
  "name": "シャイニング・フォースⅡ 古えの封印",
  "maker": "セガ",
  "releaseDate": "1993/10/1",
  "price": "8800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "363",
  "ini": "こ",
  "name": "コラムスⅢ 対決！コラムスワールド",
  "maker": "セガ",
  "releaseDate": "1993/10/15",
  "price": "6800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "364",
  "ini": "う",
  "name": "Vay 流星の鎧",
  "maker": "シムス",
  "releaseDate": "1993/10/22",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "365",
  "ini": "へ",
  "name": "ペブルビーチの波涛",
  "maker": "セガ",
  "releaseDate": "1993/10/29",
  "price": "8800",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "366",
  "ini": "り",
  "name": "リーサルエンフォーサーズ",
  "maker": "コナミ",
  "releaseDate": "1993/10/29",
  "price": "9800",
  "genre": "ガンシューティング",
  "icNo": "5"
 },
 {
  "id": "367",
  "ini": "は",
  "name": "パーティクイズ MEGA Q",
  "maker": "セガ",
  "releaseDate": "1993/11/5",
  "price": "6800",
  "genre": "クイズ",
  "icNo": "1"
 },
 {
  "id": "368",
  "ini": "し",
  "name": "雀皇登竜門",
  "maker": "セガ",
  "releaseDate": "1993/11/5",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "369",
  "ini": "あ",
  "name": "アラジン",
  "maker": "セガ",
  "releaseDate": "1993/11/12",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "370",
  "ini": "た",
  "name": "ダークウィザード 蘇りし闇の魔導士",
  "maker": "セガ",
  "releaseDate": "1993/11/12",
  "price": "6800",
  "genre": "シミュレーション・RPG",
  "icNo": "5"
 },
 {
  "id": "371",
  "ini": "す",
  "name": "スペースファンキー B.O.B.",
  "maker": "EAV",
  "releaseDate": "1993/11/19",
  "price": "8900",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "372",
  "ini": "ふ",
  "name": "フリントストーン",
  "maker": "タイトー",
  "releaseDate": "1993/11/19",
  "price": "3880",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "373",
  "ini": "あ",
  "name": "アルスラーン戦記",
  "maker": "セガ",
  "releaseDate": "1993/11/19",
  "price": "7800",
  "genre": "シミュレーション・RPG",
  "icNo": "5"
 },
 {
  "id": "374",
  "ini": "な",
  "name": "ナイトトラップ",
  "maker": "セガ",
  "releaseDate": "1993/11/19",
  "price": "8800",
  "genre": "アドベンチャー・ムービー",
  "icNo": "5"
 },
 {
  "id": "375",
  "ini": "み",
  "name": "MiG-29",
  "maker": "テンゲン",
  "releaseDate": "1993/11/26",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "376",
  "ini": "き",
  "name": "キング・オブ・ザ・モンスターズ",
  "maker": "セガ",
  "releaseDate": "1993/11/26",
  "price": "6800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "377",
  "ini": "て",
  "name": "テクモスーパーボウル",
  "maker": "テクモ",
  "releaseDate": "1993/11/26",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "378",
  "ini": "あ",
  "name": "アルシャーク",
  "maker": "サンドストーム",
  "releaseDate": "1993/11/26",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "379",
  "ini": "ま",
  "name": "マイト アンド マジックⅢ",
  "maker": "CRI",
  "releaseDate": "1993/11/26",
  "price": "6800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "380",
  "ini": "さ",
  "name": "ザ・サード ワールド ウォー",
  "maker": "マイクロネット",
  "releaseDate": "1993/11/26",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "381",
  "ini": "て",
  "name": "T.M.N.T. トーナメントファイターズ",
  "maker": "コナミ",
  "releaseDate": "1993/12/3",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "382",
  "ini": "た",
  "name": "ダイナブラザーズ2",
  "maker": "CRI",
  "releaseDate": "1993/12/3",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "383",
  "ini": "そ",
  "name": "ソニックスピンボール",
  "maker": "セガ",
  "releaseDate": "1993/12/10",
  "price": "6800",
  "genre": "ピンボール",
  "icNo": "1"
 },
 {
  "id": "384",
  "ini": "と",
  "name": "ドラゴンズリベンジ",
  "maker": "テンゲン",
  "releaseDate": "1993/12/10",
  "price": "7800",
  "genre": "ピンボール",
  "icNo": "1"
 },
 {
  "id": "385",
  "ini": "り",
  "name": "リーサルエンフォーサーズ",
  "maker": "コナミ",
  "releaseDate": "1993/12/10",
  "price": "9800",
  "genre": "ガンシューティング",
  "icNo": "1"
 },
 {
  "id": "386",
  "ini": "ゆ",
  "name": "夢見館の物語",
  "maker": "セガ",
  "releaseDate": "1993/12/10",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "387",
  "ini": "さ",
  "name": "サイキック・ディテクティブ・シリーズVol.4 オルゴール",
  "maker": "データウエスト",
  "releaseDate": "1993/12/10",
  "price": "7600",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "388",
  "ini": "し",
  "name": "Ｊリーグ プロストライカー 完全版",
  "maker": "セガ",
  "releaseDate": "1993/12/17",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "389",
  "ini": "し",
  "name": "ジャングルストライク　受け継がれた狂気",
  "maker": "EAV",
  "releaseDate": "1993/12/17",
  "price": "8900",
  "genre": "シューティング",
  "icNo": "2"
 },
 {
  "id": "390",
  "ini": "ふ",
  "name": "ファンタシースター 千年紀の終りに",
  "maker": "セガ",
  "releaseDate": "1993/12/17",
  "price": "8800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "391",
  "ini": "ふ",
  "name": "ブギウギ・ボーリング",
  "maker": "ビスコ",
  "releaseDate": "1993/12/17",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "392",
  "ini": "め",
  "name": "メタルファング",
  "maker": "ビクターE",
  "releaseDate": "1993/12/17",
  "price": "6800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "393",
  "ini": "は",
  "name": "遥かなるオーガスタ",
  "maker": "T&Eソフト",
  "releaseDate": "1993/12/17",
  "price": "8800",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "394",
  "ini": "は",
  "name": "バトルマニア大吟醸",
  "maker": "ビック東海",
  "releaseDate": "1993/12/24",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "395",
  "ini": "お",
  "name": "オーサムポッサム",
  "maker": "テンゲン",
  "releaseDate": "1993/12/25",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "396",
  "ini": "く",
  "name": "クルーボール",
  "maker": "EAV",
  "releaseDate": "1993/12/26",
  "price": "6800",
  "genre": "ピンボール",
  "icNo": "2"
 },
 {
  "id": "397",
  "ini": "せ",
  "name": "戦国伝承",
  "maker": "サミー",
  "releaseDate": "1993/12/28",
  "price": "8500",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "398",
  "ini": "ふ",
  "name": "フラッシュバック",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1993/12/29",
  "price": "8000",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "399",
  "ini": "ま",
  "name": "魔天の創滅",
  "maker": "講談社総研",
  "releaseDate": "1993/12/29",
  "price": "8900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "400",
  "ini": "し",
  "name": "ジェネラル・カオス 大混戦",
  "maker": "EAV",
  "releaseDate": "1994/1/14",
  "price": "8900",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "401",
  "ini": "り",
  "name": "龍虎の拳",
  "maker": "セガ",
  "releaseDate": "1994/1/14",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "402",
  "ini": "て",
  "name": "デビルズコース",
  "maker": "セガ",
  "releaseDate": "1994/1/28",
  "price": "8800",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "403",
  "ini": "ゆ",
  "name": "幽☆遊☆白書 外伝",
  "maker": "セガ",
  "releaseDate": "1994/1/28",
  "price": "8800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "404",
  "ini": "い",
  "name": "いしいひさいちの大政界",
  "maker": "セガ",
  "releaseDate": "1994/1/28",
  "price": "7800",
  "genre": "政治・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "405",
  "ini": "え",
  "name": "NFL フットボール'94",
  "maker": "セガ",
  "releaseDate": "1994/2/4",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "406",
  "ini": "う",
  "name": "ウィンターオリンピック",
  "maker": "セガ",
  "releaseDate": "1994/2/11",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "407",
  "ini": "え",
  "name": "NFL プロフットボール'94",
  "maker": "EAV",
  "releaseDate": "1994/2/18",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "408",
  "ini": "え",
  "name": "エアーマネジメントⅡ 航空王をめざせ",
  "maker": "光栄",
  "releaseDate": "1994/2/18",
  "price": "12800",
  "genre": "経営・シミュレーション",
  "icNo": "1"
 },
 {
  "id": "409",
  "ini": "え",
  "name": "エターナルチャンピオンズ",
  "maker": "セガ",
  "releaseDate": "1994/2/18",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "410",
  "ini": "く",
  "name": "クールスポット",
  "maker": "ヴァージン",
  "releaseDate": "1994/2/18",
  "price": "8000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "411",
  "ini": "て",
  "name": "T2 ジ・アーケードゲーム",
  "maker": "アクレイム",
  "releaseDate": "1994/2/25",
  "price": "8800",
  "genre": "ガンシューティング",
  "icNo": "1"
 },
 {
  "id": "412",
  "ini": "て",
  "name": "デビスカップ",
  "maker": "テンゲン",
  "releaseDate": "1994/2/25",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "413",
  "ini": "わ",
  "name": "ワイアラエの奇蹟",
  "maker": "セガ",
  "releaseDate": "1994/2/25",
  "price": "8800",
  "genre": "スポーツ・3D",
  "icNo": "1"
 },
 {
  "id": "414",
  "ini": "の",
  "name": "信長の野望 覇王伝",
  "maker": "光栄",
  "releaseDate": "1994/2/25",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "415",
  "ini": "し",
  "name": "真・女神転生",
  "maker": "シムス",
  "releaseDate": "1994/2/25",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "416",
  "ini": "ま",
  "name": "マイクロコズム",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/2/25",
  "price": "8900",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "417",
  "ini": "に",
  "name": "2020年スーパーベースボール",
  "maker": "EAV",
  "releaseDate": "1994/3/4",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "418",
  "ini": "て",
  "name": "テクモ スーパーNBA バスケットボール",
  "maker": "テクモ",
  "releaseDate": "1994/3/4",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "419",
  "ini": "は",
  "name": "ハイパーダンク　ザ・プレイオフエディション",
  "maker": "コナミ",
  "releaseDate": "1994/3/4",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "420",
  "ini": "く",
  "name": "クレヨンしんちゃん 嵐を呼ぶ園児",
  "maker": "マーバ",
  "releaseDate": "1994/3/11",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "421",
  "ini": "は",
  "name": "バーチャレーシング",
  "maker": "セガ",
  "releaseDate": "1994/3/18",
  "price": "9800",
  "genre": "レース・3D",
  "icNo": "3"
 },
 {
  "id": "422",
  "ini": "は",
  "name": "バンパイアキラー",
  "maker": "コナミ",
  "releaseDate": "1994/3/18",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "423",
  "ini": "へ",
  "name": "ベアナックルⅢ",
  "maker": "セガ",
  "releaseDate": "1994/3/18",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "424",
  "ini": "け",
  "name": "ゲームのかんづめ VOL.1",
  "maker": "セガ",
  "releaseDate": "1994/3/18",
  "price": "3980",
  "genre": "その他",
  "icNo": "5"
 },
 {
  "id": "425",
  "ini": "け",
  "name": "ゲームのかんづめ VOL.2",
  "maker": "セガ",
  "releaseDate": "1994/3/18",
  "price": "3980",
  "genre": "その他",
  "icNo": "5"
 },
 {
  "id": "426",
  "ini": "え",
  "name": "F1サーカスCD",
  "maker": "ニチブツ",
  "releaseDate": "1994/3/18",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "5"
 },
 {
  "id": "427",
  "ini": "は",
  "name": "ハイムドール",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/3/18",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "428",
  "ini": "た",
  "name": "WWFロイヤルランブル",
  "maker": "アクレイム",
  "releaseDate": "1994/3/25",
  "price": "8900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "429",
  "ini": "う",
  "name": "ヴイ・ファイヴ",
  "maker": "テンゲン",
  "releaseDate": "1994/3/25",
  "price": "7800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "430",
  "ini": "た",
  "name": "タイムドミネーター",
  "maker": "ビック東海",
  "releaseDate": "1994/3/25",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "431",
  "ini": "の",
  "name": "信長の野望・覇王伝",
  "maker": "光栄",
  "releaseDate": "1994/3/25",
  "price": "12800",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "432",
  "ini": "え",
  "name": "AX-101",
  "maker": "セガ",
  "releaseDate": "1994/3/25",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "433",
  "ini": "う",
  "name": "ウイングコマンダー",
  "maker": "セガ",
  "releaseDate": "1994/3/25",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "434",
  "ini": "た",
  "name": "ダンジョンマスターⅡ スカルキープ",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/3/25",
  "price": "8800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "435",
  "ini": "た",
  "name": "タントアール",
  "maker": "セガ",
  "releaseDate": "1994/4/1",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "436",
  "ini": "と",
  "name": "ドラゴンボールZ 武勇列伝",
  "maker": "バンダイ",
  "releaseDate": "1994/4/1",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "437",
  "ini": "も",
  "name": "モンスターワールドⅣ",
  "maker": "セガ",
  "releaseDate": "1994/4/1",
  "price": "8800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "438",
  "ini": "ほ",
  "name": "ぽっぷるメイル",
  "maker": "セガ",
  "releaseDate": "1994/4/1",
  "price": "7800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "439",
  "ini": "ふ",
  "name": "ファンタシースター 復刻版",
  "maker": "セガ",
  "releaseDate": "1994/4/2",
  "price": "4800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "440",
  "ini": "う",
  "name": "うる星やつら ディア マイ フレンズ",
  "maker": "ゲームアーツ",
  "releaseDate": "1994/4/15",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "441",
  "ini": "は",
  "name": "バトルファンタジー",
  "maker": "マイクロネット",
  "releaseDate": "1994/4/15",
  "price": "8400",
  "genre": "対戦格闘・アクション",
  "icNo": "5"
 },
 {
  "id": "442",
  "ini": "き",
  "name": "キャプテンラング",
  "maker": "データイースト",
  "releaseDate": "1994/4/22",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "443",
  "ini": "は",
  "name": "爆伝 アンバランスゾーン",
  "maker": "ソニー・ミュージックエンタテインメント",
  "releaseDate": "1994/4/22",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "444",
  "ini": "あ",
  "name": "アイ・オブ・ザ・ビホルダー",
  "maker": "ポニーキャニオン",
  "releaseDate": "1994/4/22",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "445",
  "ini": "へ",
  "name": "ヘブンリーシンフォニー",
  "maker": "セガ",
  "releaseDate": "1994/4/23",
  "price": "7800",
  "genre": "レース・3D",
  "icNo": "5"
 },
 {
  "id": "446",
  "ini": "え",
  "name": "NBA JAM",
  "maker": "アクレイム",
  "releaseDate": "1994/4/29",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "447",
  "ini": "あ",
  "name": "アウトランナーズ",
  "maker": "セガ",
  "releaseDate": "1994/5/13",
  "price": "7800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "448",
  "ini": "か",
  "name": "仮面ライダーZO",
  "maker": "東映ビデオ",
  "releaseDate": "1994/5/13",
  "price": "7800",
  "genre": "アドベンチャー・ムービー",
  "icNo": "5"
 },
 {
  "id": "449",
  "ini": "う",
  "name": "ウィンブルドン",
  "maker": "セガ",
  "releaseDate": "1994/5/20",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "450",
  "ini": "ろ",
  "name": "ロードス島戦記 英雄戦争",
  "maker": "セガ",
  "releaseDate": "1994/5/20",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "451",
  "ini": "え",
  "name": "Ｆ117ステルス オペレーション:ナイトストーム",
  "maker": "EAV",
  "releaseDate": "1994/5/27",
  "price": "9800",
  "genre": "シューティング・3D",
  "icNo": "2"
 },
 {
  "id": "452",
  "ini": "く",
  "name": "グレイテストヘビーウェイツ",
  "maker": "セガ",
  "releaseDate": "1994/5/27",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "453",
  "ini": "そ",
  "name": "ソニック・ザ・ヘッジホッグ3",
  "maker": "セガ",
  "releaseDate": "1994/5/27",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "454",
  "ini": "も",
  "name": "モータルコンバット",
  "maker": "アクレイム",
  "releaseDate": "1994/5/27",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "455",
  "ini": "ろ",
  "name": "ロボコップ VS ターミネーター",
  "maker": "ヴァージン",
  "releaseDate": "1994/5/28",
  "price": "8900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "456",
  "ini": "も",
  "name": "モータルコンバット完全版",
  "maker": "アクレイム",
  "releaseDate": "1994/6/3",
  "price": "6800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "457",
  "ini": "と",
  "name": "ドラゴンズレア",
  "maker": "セガ",
  "releaseDate": "1994/6/3",
  "price": "6800",
  "genre": "アニメーション・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "458",
  "ini": "ふ",
  "name": "FIFA インターナショナルサッカー",
  "maker": "EAV",
  "releaseDate": "1994/6/10",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "459",
  "ini": "し",
  "name": "新創世紀ラグナセンティ",
  "maker": "セガ",
  "releaseDate": "1994/6/17",
  "price": "8000",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "460",
  "ini": "ち",
  "name": "チャックロックⅡ",
  "maker": "ヴァージン",
  "releaseDate": "1994/6/24",
  "price": "8000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "461",
  "ini": "ち",
  "name": "チャンピオンズ ワールドクラスサッカー",
  "maker": "アクレイム",
  "releaseDate": "1994/6/24",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "462",
  "ini": "ろ",
  "name": "ロードモナーク・とことん戦闘伝説",
  "maker": "セガ",
  "releaseDate": "1994/6/24",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "463",
  "ini": "か",
  "name": "餓狼伝説2 新たなる闘い",
  "maker": "タカラ",
  "releaseDate": "1994/6/24",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "464",
  "ini": "た",
  "name": "大航海時代Ⅱ",
  "maker": "光栄",
  "releaseDate": "1994/6/24",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "465",
  "ini": "た",
  "name": "WWFマニア・ツアー",
  "maker": "アクレイム",
  "releaseDate": "1994/6/24",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "466",
  "ini": "す",
  "name": "スーパーストリートファイターⅡ THE NEW CHALLENGERS",
  "maker": "カプコン",
  "releaseDate": "1994/6/25",
  "price": "10900",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "467",
  "ini": "え",
  "name": "NBAプロバスケットボール'94",
  "maker": "EAV",
  "releaseDate": "1994/7/1",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "468",
  "ini": "ひ",
  "name": "美少女戦士セーラームーン",
  "maker": "マーバ",
  "releaseDate": "1994/7/8",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "469",
  "ini": "し",
  "name": "Ｊリーグ プロストライカー2",
  "maker": "セガ",
  "releaseDate": "1994/7/15",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "470",
  "ini": "は",
  "name": "ハイブリッド・フロント",
  "maker": "セガ",
  "releaseDate": "1994/7/22",
  "price": "8800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "471",
  "ini": "は",
  "name": "パルスマン",
  "maker": "セガ",
  "releaseDate": "1994/7/22",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "472",
  "ini": "し",
  "name": "シャイニング・フォースCD",
  "maker": "セガ",
  "releaseDate": "1994/7/22",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "473",
  "ini": "し",
  "name": "シャドー・オブ・ザ・ビーストⅡ 邪神の呪縛",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/7/29",
  "price": "8800",
  "genre": "アクション",
  "icNo": "5"
 },
 {
  "id": "474",
  "ini": "た",
  "name": "ダイナマイトヘッディー",
  "maker": "セガ",
  "releaseDate": "1994/8/5",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "475",
  "ini": "は",
  "name": "パノラマコットン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1994/8/12",
  "price": "9500",
  "genre": "シューティング・3D",
  "icNo": "1"
 },
 {
  "id": "476",
  "ini": "え",
  "name": "エコー・ザ・ドルフィン2",
  "maker": "セガ",
  "releaseDate": "1994/8/26",
  "price": "6800",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "477",
  "ini": "ら",
  "name": "ラングリッサー2",
  "maker": "メサイヤ（日本コンピュータシステム）",
  "releaseDate": "1994/8/26",
  "price": "9800",
  "genre": "シミュレーション・RPG",
  "icNo": "1"
 },
 {
  "id": "478",
  "ini": "も",
  "name": "モータルコンバットⅡ 究極神拳",
  "maker": "アクレイム",
  "releaseDate": "1994/9/9",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "479",
  "ini": "こ",
  "name": "魂斗羅 ザ・ハードコア",
  "maker": "コナミ",
  "releaseDate": "1994/9/15",
  "price": "9000",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "480",
  "ini": "と",
  "name": "ドラゴンスレイヤー 英雄伝説",
  "maker": "セガ",
  "releaseDate": "1994/9/16",
  "price": "8800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "481",
  "ini": "す",
  "name": "スター・ウォーズ レベル・アサルト",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/9/22",
  "price": "8800",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "482",
  "ini": "す",
  "name": "スパークスター　ロケットナイトアドベンチャーズ2",
  "maker": "コナミ",
  "releaseDate": "1994/9/23",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "483",
  "ini": "ゆ",
  "name": "幽☆遊☆白書 魔強統一戦",
  "maker": "セガ",
  "releaseDate": "1994/9/30",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "484",
  "ini": "し",
  "name": "ジュラシックパーク",
  "maker": "セガ",
  "releaseDate": "1994/9/30",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "485",
  "ini": "き",
  "name": "キャプテン翼",
  "maker": "テクモ",
  "releaseDate": "1994/9/30",
  "price": "7800",
  "genre": "スポーツ・シミュレーション",
  "icNo": "5"
 },
 {
  "id": "486",
  "ini": "は",
  "name": "バトルコープス",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/9/30",
  "price": "8000",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "487",
  "ini": "ろ",
  "name": "ロックマン メガワールド",
  "maker": "カプコン",
  "releaseDate": "1994/10/21",
  "price": "8500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "488",
  "ini": "そ",
  "name": "ソニック&ナックルズ",
  "maker": "セガ",
  "releaseDate": "1994/10/28",
  "price": "7800",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "489",
  "ini": "す",
  "name": "スターブレード",
  "maker": "ナムコ",
  "releaseDate": "1994/10/28",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "490",
  "ini": "あ",
  "name": "アフターハルマゲドン外伝 魔獣闘将伝エクリプス",
  "maker": "セガ",
  "releaseDate": "1994/11/11",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "491",
  "ini": "こ",
  "name": "豪血寺一族",
  "maker": "アトラス",
  "releaseDate": "1994/11/18",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "492",
  "ini": "さ",
  "name": "サムライスピリッツ",
  "maker": "セガ",
  "releaseDate": "1994/11/19",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "493",
  "ini": "り",
  "name": "リーサルエンフォーサーズⅡ ザ・ウエスタン",
  "maker": "コナミ",
  "releaseDate": "1994/11/25",
  "price": "9800",
  "genre": "ガンシューティング",
  "icNo": "5"
 },
 {
  "id": "494",
  "ini": "ふ",
  "name": "ぷよぷよ通",
  "maker": "コンパイル",
  "releaseDate": "1994/12/2",
  "price": "6800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "495",
  "ini": "す",
  "name": "スターウォーズ・アーケード",
  "maker": "セガ",
  "releaseDate": "1994/12/3",
  "price": "8800",
  "genre": "シューティング・3D",
  "icNo": "6"
 },
 {
  "id": "496",
  "ini": "と",
  "name": "DOOM",
  "maker": "セガ",
  "releaseDate": "1994/12/3",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "6"
 },
 {
  "id": "497",
  "ini": "す",
  "name": "スペースハリアー",
  "maker": "セガ",
  "releaseDate": "1994/12/3",
  "price": "4980",
  "genre": "シューティング・3D",
  "icNo": "6"
 },
 {
  "id": "498",
  "ini": "す",
  "name": "ストーリー・オブ・トア 光を継ぐ者",
  "maker": "セガ",
  "releaseDate": "1994/12/9",
  "price": "8800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "499",
  "ini": "ら",
  "name": "ライオンキング",
  "maker": "ヴァージン",
  "releaseDate": "1994/12/9",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "500",
  "ini": "と",
  "name": "トムとジェリー",
  "maker": "アルトロン",
  "releaseDate": "1994/12/16",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "501",
  "ini": "な",
  "name": "ナイジェルマンセル・インディカー",
  "maker": "アクレイム",
  "releaseDate": "1994/12/16",
  "price": "7800",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "502",
  "ini": "み",
  "name": "ミッキーとミニー マジカルアドベンチャー2",
  "maker": "カプコン",
  "releaseDate": "1994/12/16",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "503",
  "ini": "は",
  "name": "バーチャレーシング デラックス",
  "maker": "セガ",
  "releaseDate": "1994/12/16",
  "price": "8800",
  "genre": "レース・3D",
  "icNo": "6"
 },
 {
  "id": "504",
  "ini": "て",
  "name": "テクモ スーパーボウル2 スペシャルエディション",
  "maker": "テクモ",
  "releaseDate": "1994/12/20",
  "price": "9980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "505",
  "ini": "え",
  "name": "NBAジャム",
  "maker": "アクレイム",
  "releaseDate": "1994/12/20",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "506",
  "ini": "る",
  "name": "ルナ エターナルブルー",
  "maker": "ゲームアーツ",
  "releaseDate": "1994/12/22",
  "price": "9800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "507",
  "ini": "と",
  "name": "トムキャットアレイ",
  "maker": "セガ",
  "releaseDate": "1994/12/22",
  "price": "7800",
  "genre": "ムービー・シューティング",
  "icNo": "5"
 },
 {
  "id": "508",
  "ini": "そ",
  "name": "ソウルスター",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1994/12/22",
  "price": "8000",
  "genre": "シューティング・3D",
  "icNo": "5"
 },
 {
  "id": "509",
  "ini": "い",
  "name": "イチダントアール",
  "maker": "セガ",
  "releaseDate": "1995/1/13",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "510",
  "ini": "あ",
  "name": "アフターバーナー・コンプリート",
  "maker": "セガ",
  "releaseDate": "1995/1/13",
  "price": "4980",
  "genre": "シューティング・3D",
  "icNo": "6"
 },
 {
  "id": "511",
  "ini": "と",
  "name": "ドラゴンスレイヤー英雄伝説Ⅱ",
  "maker": "セガ",
  "releaseDate": "1995/1/20",
  "price": "8800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "512",
  "ini": "さ",
  "name": "サイバーブロール",
  "maker": "セガ",
  "releaseDate": "1995/1/27",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "6"
 },
 {
  "id": "513",
  "ini": "り",
  "name": "リスター・ザ・シューティングスター",
  "maker": "セガ",
  "releaseDate": "1995/2/17",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "514",
  "ini": "え",
  "name": "NBA ジャム トーナメントエディション",
  "maker": "アクレイム",
  "releaseDate": "1995/2/24",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "515",
  "ini": "え",
  "name": "NFLクォーターバッククラブ'95",
  "maker": "アクレイム",
  "releaseDate": "1995/2/24",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "516",
  "ini": "え",
  "name": "エイリアンソルジャー",
  "maker": "セガ",
  "releaseDate": "1995/2/24",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "517",
  "ini": "め",
  "name": "メタルヘッド",
  "maker": "セガ",
  "releaseDate": "1995/2/24",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "6"
 },
 {
  "id": "518",
  "ini": "こ",
  "name": "ゴルフマガジン プレゼンツ 36グレートホールズ スターリング フレッド・カプルス",
  "maker": "セガ",
  "releaseDate": "1995/2/24",
  "price": "8800",
  "genre": "スポーツ・3D",
  "icNo": "6"
 },
 {
  "id": "519",
  "ini": "え",
  "name": "エコー・ザ・ドルフィンCD",
  "maker": "セガ",
  "releaseDate": "1995/2/24",
  "price": "4980",
  "genre": "アクション・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "520",
  "ini": "た",
  "name": "大封神伝",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1995/2/24",
  "price": "6800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "521",
  "ini": "さ",
  "name": "サージングオーラ",
  "maker": "セガ",
  "releaseDate": "1995/3/17",
  "price": "8800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "522",
  "ini": "て",
  "name": "TEMPO",
  "maker": "セガ",
  "releaseDate": "1995/3/24",
  "price": "7800",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "523",
  "ini": "た",
  "name": "ダブルスイッチ",
  "maker": "セガ",
  "releaseDate": "1995/3/24",
  "price": "6800",
  "genre": "ムービー・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "524",
  "ini": "ふ",
  "name": "プライズファイター",
  "maker": "セガ",
  "releaseDate": "1995/3/24",
  "price": "6800",
  "genre": "ムービー・スポーツ",
  "icNo": "5"
 },
 {
  "id": "525",
  "ini": "み",
  "name": "ミッキーマニア",
  "maker": "セガ",
  "releaseDate": "1995/3/31",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "526",
  "ini": "か",
  "name": "餓狼伝説SPECIAL",
  "maker": "ビクターエンタテインメント",
  "releaseDate": "1995/3/31",
  "price": "6800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "527",
  "ini": "か",
  "name": "カオティクス",
  "maker": "セガ",
  "releaseDate": "1995/4/21",
  "price": "7800",
  "genre": "アクション",
  "icNo": "6"
 },
 {
  "id": "528",
  "ini": "す",
  "name": "ステラアサルト",
  "maker": "セガ",
  "releaseDate": "1995/4/26",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "6"
 },
 {
  "id": "529",
  "ini": "て",
  "name": "テレビアニメ・スラムダンク　強豪真っ向対決！",
  "maker": "バンダイ",
  "releaseDate": "1995/4/28",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "530",
  "ini": "と",
  "name": "トゥルーライズ",
  "maker": "アクレイム",
  "releaseDate": "1995/4/28",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "531",
  "ini": "も",
  "name": "モータルコンバットⅡ 究極神拳",
  "maker": "アクレイム",
  "releaseDate": "1995/5/19",
  "price": "9800",
  "genre": "対戦格闘",
  "icNo": "6"
 },
 {
  "id": "532",
  "ini": "ま",
  "name": "マキシマムカーネイジ",
  "maker": "アクレイム",
  "releaseDate": "1995/5/26",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "533",
  "ini": "ら",
  "name": "ライトクルセイダー",
  "maker": "セガ",
  "releaseDate": "1995/5/26",
  "price": "7800",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "534",
  "ini": "え",
  "name": "NFL クォーターバッククラブ'95",
  "maker": "アクレイム",
  "releaseDate": "1995/7/14",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "535",
  "ini": "は",
  "name": "パラスコード",
  "maker": "セガ",
  "releaseDate": "1995/7/14",
  "price": "7800",
  "genre": "シューティング・3D",
  "icNo": "6"
 },
 {
  "id": "536",
  "ini": "さ",
  "name": "三國志Ⅳ",
  "maker": "光栄",
  "releaseDate": "1995/7/28",
  "price": "1480",
  "genre": "シミュレーション",
  "icNo": "6"
 },
 {
  "id": "537",
  "ini": "ち",
  "name": "超球界ミラクルナイン",
  "maker": "セガ",
  "releaseDate": "1995/7/21",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "538",
  "ini": "ふ",
  "name": "プロストライカー ファイナルステージ",
  "maker": "セガ",
  "releaseDate": "1995/8/4",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "539",
  "ini": "こ",
  "name": "コミックスゾーン",
  "maker": "セガ",
  "releaseDate": "1995/9/1",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "540",
  "ini": "し",
  "name": "ジャスティスリーグ",
  "maker": "アクレイム",
  "releaseDate": "1995/9/1",
  "price": "8800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "541",
  "ini": "し",
  "name": "ジャッジ・ドレッド",
  "maker": "アクレイム",
  "releaseDate": "1995/9/1",
  "price": "7800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "542",
  "ini": "え",
  "name": "NBAジャム・トーナメントエディション",
  "maker": "アクレイム",
  "releaseDate": "1995/9/1",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "543",
  "ini": "た",
  "name": "WWF RAW",
  "maker": "アクレイム",
  "releaseDate": "1995/9/1",
  "price": "9800",
  "genre": "スポーツ",
  "icNo": "6"
 },
 {
  "id": "544",
  "ini": "ふ",
  "name": "ファーレンハイト",
  "maker": "セガ",
  "releaseDate": "1995/9/1",
  "price": "6800",
  "genre": "ムービー・アドベンチャー",
  "icNo": "5"
 },
 {
  "id": "545",
  "ini": "し",
  "name": "ジ・ウーズ",
  "maker": "セガ",
  "releaseDate": "1995/9/22",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "546",
  "ini": "は",
  "name": "バーチャファイター",
  "maker": "セガ",
  "releaseDate": "1995/10/20",
  "price": "7800",
  "genre": "対戦格闘",
  "icNo": "5"
 },
 {
  "id": "547",
  "ini": "は",
  "name": "バットマン フォーエヴァー",
  "maker": "アクレイム",
  "releaseDate": "1995/10/27",
  "price": "8800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "548",
  "ini": "ふ",
  "name": "フォアマン フォーリアル",
  "maker": "アクレイム",
  "releaseDate": "1995/10/27",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "549",
  "ini": "へ",
  "name": "ぺぺんがPENGO",
  "maker": "セガ",
  "releaseDate": "1995/12/22",
  "price": "4980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "550",
  "ini": "さ",
  "name": "サージカルストライク",
  "maker": "セガ",
  "releaseDate": "1995/12/22",
  "price": "6800",
  "genre": "ムービー・シューティング",
  "icNo": "5"
 },
 {
  "id": "551",
  "ini": "た",
  "name": "WWF RAW",
  "maker": "アクレイム",
  "releaseDate": "1995/12/31",
  "price": "8800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "552",
  "ini": "は",
  "name": "バーチャルバート",
  "maker": "アクレイム",
  "releaseDate": "1995/12/31",
  "price": "8800",
  "genre": "ミニゲーム",
  "icNo": "1"
 },
 {
  "id": "553",
  "ini": "し",
  "name": "シャドウラン",
  "maker": "コンパイル",
  "releaseDate": "1996/2/23",
  "price": "7800",
  "genre": "RPG",
  "icNo": "5"
 },
 {
  "id": "554",
  "ini": "ま",
  "name": "魔導物語Ⅰ",
  "maker": "コンパイル",
  "releaseDate": "1996/3/22",
  "price": "7800",
  "genre": "RPG",
  "icNo": "1"
 }
];